import CourseDescription from "./components/Layout/CourseDescription";
import FeatureGrid from "./components/Layout/FeatureGrid";
import Header from "./components/Layout/Header";
import Seminars from "./components/Layout/Seminars";
import TeamGrid from "./components/Layout/TeamGrid";
import RegistrationSteps from "./components/Layout/RegistrationSteps"
import Footer from "./components/Layout/Footer";
import HamburgerMenu from "./components/Layout/HamburgerMenu";
import { LanguageProvider } from "./store/LanguageContext";

function App() {
    return (
        <LanguageProvider>
            <HamburgerMenu />
            <Header/>
            <CourseDescription/>
            <FeatureGrid/>
            <TeamGrid />
            <Seminars />
            <RegistrationSteps />
            <Footer />
        </LanguageProvider>
    );
}

export default App;
